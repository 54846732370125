import React, {useEffect, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import {generateClient} from 'aws-amplify/api'

Amplify.configure(awsconfig);


const subscribeEmailMutation = `
  mutation SendEmail($email: String!) {
    subscribeEmail(email: $email)
    {
        email  
    }
  }
`;

const backgroundImages = [
    './Castle-site2.webp',
    './Castle-site3.webp',
    './Castle-site4.webp',
    './Castle-site5.webp'
];

const shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
};

const App = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [email, setEmail] = useState('');

    useEffect(() => {
        shuffleArray(backgroundImages);

        const changeImage = () => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % backgroundImages.length);
        };

        const interval = setInterval(changeImage, 40000); // Change every 40 seconds

        return () => clearInterval(interval);

    }, []);


    const handleFormSubmit = async (event: any) => {
        console.log(event)

        event.preventDefault();


        console.log(`Submit here ${email}`);


        if (email) {
            try {
                const input = {email};
                const client = generateClient();


                await client.graphql({
                    query: subscribeEmailMutation,
                    variables: input
                });
                toast.success('Email submitted successfully!');
                setEmail(''); // Reset the email input
            } catch (error) {
                toast.error(`${error}`);
            }
        }
    };


    const handleChange = (event: any) => {
        setEmail(event.target.value);
    };


    return (
        <main className={`background-changer`}
              style={{backgroundImage: `url(${backgroundImages[currentImageIndex]})`}}>
            <div className="signup-container">
                <h1>Welcome to Tales and Legends!</h1>
                <p>Enter your email to join the fun.</p>
            </div>
            <div className="signup-bar">
                <div className="signup-content">
                    <div className="profile-pic">
                        <img src="Tales%20and%20Legends%20Fox.jpg" alt="Tales and legends fox"/>
                    </div>
                    <div className="signup-form">
                        <form id="mc-embedded-subscribe-form" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    id="mce-EMAIL"
                                    value={email}
                                    required
                                    onChange={handleChange}
                                />
                                <label htmlFor="mce-EMAIL">Email Address</label>
                            </div>
                            <button type="submit" id="mc-embedded-subscribe">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </main>
    );
};

export default App;


